const footer = document.querySelector('footer');
if(footer) {
    const columns = footer.querySelectorAll('.footer-middle .column');
    if (columns) {
        columns.forEach(toggle => {
            toggle.addEventListener("click", () => {
                if (window.innerWidth <= 1060) {
                    toggle.classList.toggle('active');

                    columns.forEach(column => {
                        if (column !== toggle) {
                            column.classList.remove('active');
                        }
                    });
                }
            });
        });
    }
}

const welcomeModal = document.querySelector('.welcome-modal');

if (welcomeModal) {
    function showModalOnce () {
        const modalShown = localStorage.getItem('welcomeModal');

        if (!modalShown) {
            setTimeout(function () {
                welcomeModal.classList.add('active');
                document.querySelector('body').classList.add('fixed');
            }, 3000);
        }
    }

    const closeBtn = welcomeModal.querySelector('.close-form-btn');
    const proceedBtn = welcomeModal.querySelector('.modal-cta');

    if (closeBtn) {
        closeBtn.addEventListener('click', function () {
            hideModal()
        })
    }

    if (proceedBtn) {
        proceedBtn.addEventListener('click', function () {
            hideModal()
        })
    }

    document.addEventListener("keydown", ({key}) => {
        if (key === "Escape")  {
            hideModal()
        }
    })


    function hideModal () {
        if (welcomeModal.classList.contains('active')) {
            welcomeModal.classList.remove('active');
            document.querySelector('body').classList.remove('fixed');
            localStorage.setItem('welcomeModal', true);
        }
    }

    showModalOnce();
}