const mobileMenu = document.querySelector('.mobile-menu');
const menuTrigger = document.querySelector('header nav .hamburger');
const body = document.querySelector('body');
menuTrigger.addEventListener('click', function () {
    if (mobileMenu.classList.contains('mobile-menu-open')) {
        mobileMenu.classList.remove('mobile-menu-open');
        body.classList.remove('fixed');
        menuTrigger.classList.remove('active');
    } else {
        mobileMenu.classList.add('mobile-menu-open');
        body.classList.add('fixed');
        menuTrigger.classList.add('active');
    }
})

document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
        if (mobileMenu.classList.contains('mobile-menu-open')) {
            mobileMenu.classList.remove('mobile-menu-open');
            body.classList.remove('fixed');
            menuTrigger.classList.remove('active');
        }
    }
});

document.addEventListener('DOMContentLoaded', function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}, false);

window.onresize = function(event) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};